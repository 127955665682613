import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [5,6,7,2,3,4];

export const dictionary = {
		"/": [~8],
		"/hello": [~17],
		"/login": [~18],
		"/logout": [~19],
		"/payment/success": [~20],
		"/privacy": [21],
		"/pros/[tutor]": [~22,[5]],
		"/pros/[tutor]/class/[cls]": [23,[5,6]],
		"/pros/[tutor]/class/[cls]/complete": [24,[5,6]],
		"/pros/[tutor]/class/[cls]/edit": [25,[5,6]],
		"/terms": [26],
		"/tutor/apply-featured": [~27,[7]],
		"/tutor/become/creator": [~28,[7]],
		"/tutor/become/tutor": [~29,[7]],
		"/tutor/edit": [30,[7]],
		"/tutor/edit/plans": [31,[7]],
		"/tutor/edit/trial": [~32,[7]],
		"/tutor/edit/workdays": [33,[7]],
		"/tutor/me": [~34,[7]],
		"/tutor/me/balance": [~35,[7]],
		"/tutor/me/withdraw": [~36,[7]],
		"/tutor/me/withdraw/paypal": [~37,[7]],
		"/tutor/me/withdraw/setup/paypal": [38,[7]],
		"/tutor/me/withdraw/setup/stripe": [~39,[7]],
		"/tutor/me/withdraw/stripe": [~40,[7]],
		"/tutor/me/withdraw/success": [41,[7]],
		"/tutor/referrals": [~42,[7]],
		"/tutor/squad/all": [~43,[7]],
		"/tutor/squad/edit/[squad]": [~44,[7]],
		"/tutor/username": [~45,[7]],
		"/[tutor]": [9,[2]],
		"/[tutor]/invite": [16,[2]],
		"/[tutor]/[plan]": [~10,[2,3]],
		"/[tutor]/[plan]/book": [~11,[2,3]],
		"/[tutor]/[plan]/class/[cls]": [12,[2,3,4]],
		"/[tutor]/[plan]/class/[cls]/complete": [13,[2,3,4]],
		"/[tutor]/[plan]/class/[cls]/edit": [~14,[2,3,4]],
		"/[tutor]/[plan]/order": [~15,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';